import React from "react";

function Buttons({
  specificButtonTitle,
  specificButtonStyling,
  specificButtonTextStyling,
  specificButtonText,
  buttonDotSymbolStyling,
  specificButtonIcon,
  specificButtonClick,
}) {
  return (
    <button
      onClick={() => specificButtonClick && specificButtonClick()}
      type="button"
      title={specificButtonTitle}
      className={specificButtonStyling}
    >
      {specificButtonText && (
        <p className={specificButtonTextStyling}>
          {specificButtonText}{" "}
          {buttonDotSymbolStyling && (
            <span className={buttonDotSymbolStyling}>•</span>
          )}
        </p>
      )}

      {specificButtonIcon && specificButtonIcon}
    </button>
  );
}

export default Buttons;
