import React, { useContext } from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";
import profileImage from "../../assets/img/profile-image.png";
import { ReactComponent as Instagram } from "../../assets/svg/landing-page/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/svg/landing-page/twitter.svg";
import { ReactComponent as Facebook } from "../../assets/svg/landing-page/facebook.svg";
import { ReactComponent as Youtube } from "../../assets/svg/landing-page/youtube.svg";
import { ReactComponent as Tiktok } from "../../assets/svg/landing-page/tiktok.svg";
import { ReactComponent as Phone } from "../../assets/svg/landing-page/phone.svg";
import { ReactComponent as Email } from "../../assets/svg/landing-page/email.svg";
import { ReactComponent as Address } from "../../assets/svg/landing-page/address.svg";

function Social() {
  return (
    <div>
      <div className="flex items-center justify-evenly">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/ategbemovement"
        >
          <Instagram
            title="Instagram"
            className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/AtegbeMovement"
        >
          <Twitter
            title="Twitter"
            className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/Ategbe27/"
        >
          <Facebook
            title="Facebook"
            className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/@ategbemovement9879"
        >
          <Youtube
            title="Youtube"
            className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.tiktok.com/@ategbemovement8"
        >
          <Tiktok
            title="Tiktok"
            className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
      </div>

      <div className="mt-[30px] flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
            <Phone title="Phone" className="mr-4" />
            <a href="tel:+2347041516797" rel="noreferrer">
              <p className="font-FaktumBold hover:text-ategbe-green">
                (+234) 704-151-6797
              </p>
            </a>
          </div>
          <div className="flex items-center justify-center cursor-pointer mt-[5px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
            <Email title="Email" className="mr-4" />
            <a rel="noreferrer" href="mailto:AtegbeMedia@gmail.com">
              <p className="font-FaktumBold hover:text-ategbe-green">
                AtegbeMedia@gmail.com
              </p>
            </a>
          </div>
          <div className="flex items-center justify-center cursor-pointer mt-[5px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
            <Address title="Address" className="mr-4" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://maps.app.goo.gl/iEPWPmLMsyrxUCpJ7"
            >
              <p className="font-FaktumBold hover:text-ategbe-green">
                13 Akinsanya, Ajao Estate (Airport Road), Lagos Nigeria.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function SocialMobile() {
  return (
    <div>
      <div className="flex items-center justify-evenly">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/ategbemovement"
        >
          <Instagram
            title="Instagram"
            className="w-[25px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/AtegbeMovement"
        >
          <Twitter
            title="Twitter"
            className="w-[25px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/Ategbe27/"
        >
          <Facebook
            title="Facebook"
            className="w-[25px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/@ategbemovement9879"
        >
          <Youtube
            title="Youtube"
            className="w-[25px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.tiktok.com/@ategbemovement8"
        >
          <Tiktok
            title="Tiktok"
            className="w-[25px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
          />
        </a>
      </div>

      <div className="mt-[20px] flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
            <Phone title="Phone" className="w-[20px] mr-2" />
            <a href="tel:+2347041516797" rel="noreferrer">
              <p className="font-FaktumBold hover:text-ategbe-green">
                (+234) 704-151-6797
              </p>
            </a>
          </div>
          <div className="flex items-center justify-center cursor-pointer mt-[5px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
            <Email title="Email" className="w-[20px] mr-4" />
            <a rel="noreferrer" href="mailto:AtegbeMedia@gmail.com">
              <p className="font-FaktumBold hover:text-ategbe-green">
                AtegbeMedia@gmail.com
              </p>
            </a>
          </div>
          <div className="flex items-center justify-center cursor-pointer mt-[5px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
            <Address title="Address" className="w-[30px] mr-4" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://maps.app.goo.gl/iEPWPmLMsyrxUCpJ7"
            >
              <p className="font-FaktumBold hover:text-ategbe-green text-[12px]">
                13 Akinsanya, Ajao Estate (Airport Road), Lagos Nigeria.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Section6Contact() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full flex items-center justify-center my-10">
        <div className="px-[150px] pb-[20px] w-full">
          <p className="text-center mb-[50px] text-[106px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular cursor-default">
            {currentLanguage === "portuguese" ? "contato" : "contact"}
          </p>
          <div className="mt-[180px]">
            <div className="flex items-center justify-center">
              <div className="absolute bg-ategbe-brown border-black border-[10px] shadow-css w-[250px] h-[250px] rounded-full flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
                <img
                  src={profileImage}
                  alt="hero"
                  className="w-[186.78px] h-[172px] -ml-3 -mt-3"
                />
              </div>
            </div>
            <div className="bg-ategbe-brown  shadow-css w-full  px-[20px] rounded-[10px] flex items-center justify-center">
              <div className="px-[100px] pt-[154px] pb-[60px] text-left w-full flex justify-center text-[20px] leading-[32.2px] text-[#D9D9D9] font-FaktumRegular cursor-default">
                {currentLanguage === "portuguese" ? (
                  <div className="mt-[34px]">
                    <Social />
                    <p className="mt-[34px]">
                      A companhia possui uma rede de oficiais experientes e
                      determinados que dão seu melhor com sua capacidade para
                      cumprir suas tarefas.
                    </p>
                    <p className="mt-[34px]">
                      Além disso, nossa filosofia de operação é baseada no
                      crescimento comum, enquanto oferecemos oportunidades para
                      suportes locais e colaborações. Acreditamos que podemos
                      construir uma relação saudável em nossa comunidade, para
                      incentivar um crescimento nacional desde os primeiros
                      passos.
                    </p>
                    <p className="mt-[34px]">
                      Por isso, a companhia projeta um espírito comunal flexível
                      enquanto incorporamos parcerias, colaborações e
                      investimentos.
                    </p>
                    <div className="text-right">
                      <p className="mt-[40px]">Seus,</p>
                      <p className="mt-[40px] font-CorinthiaRegular text-[90px]">
                        Ategbe
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-[34px]">
                    <Social />
                    <div>
                      <p className="mt-[34px]">
                        The company through its commitment to the service of
                        humanity has a very strong network of trained,
                        determined and experienced officials that would always
                        strive all within their legal capacity in completing
                        given tasks.
                      </p>
                      <p className="mt-[34px]">
                        Also, our philosophy of operation is based on communal
                        growth as we provide opportunities for local support and
                        collaborations. We believe in building healthy
                        relationship in our communities so as to foster national
                        development starting from the grassroots.
                      </p>
                      <p className="mt-[34px]">
                        Thus, the company projects a flexible communal spirit as
                        we tirelessly look forward to more healthy partnership,
                        collaborations and investments.
                      </p>
                      <div className="text-right">
                        <p className="mt-[40px]">Yours,</p>
                        <p className="mt-[40px] font-CorinthiaRegular text-[90px]">
                          Ategbe
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full h-full my-[6px] flex items-center justify-center">
        <div className="px-[5px] pt-[20px] pb-[40px] w-10/12">
          <p className="text-center text-[56px] leading-[51px] text-ategbe-font-white font-AfricaVibesRegular">
            {currentLanguage === "portuguese" ? "contato" : "contact"}
          </p>
          <div className="mt-[110px]">
            <div className="flex items-center justify-center">
              <div className="absolute bg-ategbe-brown border-black border-[5px] shadow-css w-[150px] h-[150px] rounded-full flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
                <img
                  src={profileImage}
                  alt="hero"
                  className="w-[100.78px] h-[95px] -ml-3 -mt-3"
                />
              </div>
            </div>
            <div className="bg-ategbe-brown shadow-css w-full px-[20px] rounded-[10px] flex items-center justify-center">
              <div className="px-[20px] pt-[90px] pb-[30px] text-left w-full flex justify-center text text-[15px] text-[#D9D9D9] font-FaktumRegular cursor-default">
                {currentLanguage === "portuguese" ? (
                  <div className="mt-[10px]">
                    <SocialMobile />
                    <p className="mt-[30px]">
                      A companhia possui uma rede de oficiais experientes e
                      determinados que dão seu melhor com sua capacidade para
                      cumprir suas tarefas.
                    </p>
                    <p className="mt-[24px]">
                      Além disso, nossa filosofia de operação é baseada no
                      crescimento comum, enquanto oferecemos oportunidades para
                      suportes locais e colaborações. Acreditamos que podemos
                      construir uma relação saudável em nossa comunidade, para
                      incentivar um crescimento nacional desde os primeiros
                      passos.
                    </p>
                    <p className="mt-[24px]">
                      Por isso, a companhia projeta um espírito comunal flexível
                      enquanto incorporamos parcerias, colaborações e
                      investimentos.
                    </p>
                    <div className="text-right">
                      <p className="mt-[30px]">Seus,</p>
                      <p className="mt-[-10px] font-CorinthiaRegular text-[60px]">
                        Ategbe
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-[10px]">
                    <SocialMobile />
                    <div>
                      <p className="mt-[30px]">
                        The company through its commitment to the service of
                        humanity has a very strong network of trained,
                        determined and experienced officials that would always
                        strive all within their legal capacity in completing
                        given tasks.
                      </p>
                      <p className="mt-[24px]">
                        Also, our philosophy of operation is based on communal
                        growth as we provide opportunities for local support and
                        collaborations. We believe in building healthy
                        relationship in our communities so as to foster national
                        development starting from the grassroots.
                      </p>
                      <p className="mt-[24px]">
                        Thus, the company projects a flexible communal spirit as
                        we tirelessly look forward to more healthy partnership,
                        collaborations and investments.
                      </p>
                      <div className="text-right">
                        <p className="mt-[30px]">Yours,</p>
                        <p className="mt-[-10px] font-CorinthiaRegular text-[60px]">
                          Ategbe
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6" />
        </>
      )}
    </div>
  );
}

export default Section6Contact;
