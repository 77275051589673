/* eslint-disable react/jsx-no-constructed-context-values */
import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { NonAuthRoutes } from "./urls";
import AtegbeIcon from "../assets/img/ategbe-icon.png";
import LandingPage from "../pages/landing-page/landing-page";
import { LanguageContext } from "../utils/context";
import LanguageDialog from "../components/language-dialog/language-dialog";

// const LandingPage = React.lazy(() =>
//   import("../pages/landing-page/landing-page")
// );

function Routers() {
  const [currentLanguage, setCurrentLanguage] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const getLanguage = localStorage.getItem("currentLanguage");
    if (getLanguage !== null) {
      setCurrentLanguage(getLanguage);
    }

    return function cleanup() {
      ac.abort();
    };
  }, []);

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <div>
              <img src={AtegbeIcon} alt="Ategbe Icon" />
              <p className="text-center">Loading...</p>
            </div>
          </div>
        }
      >
        <Routes>
          <Route
            exact
            path={NonAuthRoutes.landingPage}
            element={<LandingPage />}
          />
        </Routes>
        {currentLanguage === null && (
          <LanguageDialog
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
          />
        )}
      </Suspense>
    </LanguageContext.Provider>
  );
}
export default Routers;
