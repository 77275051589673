import React, { useContext } from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";

function Section2About() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full flex items-center justify-center my-10">
        <div className="border-[3px] shadow-css px-[200px] pt-[54px] pb-[90px] bg-ategbe-brown border-ategbe-green rounded-[100px] w-10/12">
          <p className="text-center text-[106px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular cursor-default">
            {currentLanguage === "portuguese"
              ? "Sobre ategbe movement"
              : "about ategbe movement"}
          </p>
          <div className="text-left w-full flex justify-center text-[20px] leading-[32.2px] text-[#D9D9D9] font-FaktumRegular cursor-default">
            {currentLanguage === "portuguese" ? (
              <div>
                <p className="mt-[44px]">
                  Fundado em 2020, Ategbe Media tem como objetivo ser um canal
                  líder na promoção, proteção e restauração da fascinante e
                  invejável cultura do povo negro. A companhia trabalha em
                  conjunto com o Projeto Olú-Aprenda Yorùbá, do Brasil, dentre
                  muitos outros.
                </p>
                <p className="mt-[44px]">
                  Nós conduzimos sessões e aulas sobre Ifá e Orixá e engajamos
                  nossa audiência e estudantes através de programas, podcasts e
                  uma série de documentários sobre o Isese. Além disso, a
                  instituição encoraja o aprendizado presencial enquanto
                  oferecemos treinamento para aqueles que pretendem trabalhar
                  com criação de vídeos (na Nigéria) e aqueles que aderem ao
                  Isese através do estudo de Ifá e dos orixás.
                </p>
                <p className="mt-[44px]">
                  A instituição tem ramos em diferentes cidades pela Nigéria,
                  incluindo Lagos, Ibadan, Ife e Osogbo. A sede está localizada
                  em Lagos Ikeja e está aberta para negócios todos os dias.
                </p>

                <div className="w-full mt-[44px] flex items-center justify-center cursor-pointer">
                  <div className="flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
                    <iframe
                      className="rounded-xl"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/T79i1u04VjM?si=psfJUtSFJMWeELLA"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className=" mt-[44px]">
                  Established in 2020. Ategbe media sets out itself to be a
                  leading media hub for the promotion, protection and
                  restoration of the enviable and fascinating culture of the
                  black race. The company is in partnership with projeto olú
                  (learn yòrùbá) from Brazil amongst many others.
                </p>

                <p className=" mt-[24px] ">
                  We conduct online session and classes on IFA and Orisa worship
                  and practices engaging our audience cum students through our
                  live programmes, podcasts and series of Isese documentaries.
                  Also, the company encourages physical learning as we offer
                  training to aspiring videographers and intending adherents of
                  Isese through IFA and Orisa studies.
                </p>

                <p className=" mt-[24px]  mb-[54px]">
                  The company has branches in major different town and cities of
                  Nigeria including Lagos, Ibadan, Ife and Osogbo. The Lagos
                  Ikeja branch serves as the headquarters as it is always
                  reachable and open to businesses 24 hours everyday.
                </p>

                <div className="w-full mt-[44px] flex items-center justify-center cursor-pointer">
                  <div className="flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
                    <iframe
                      className="rounded-xl"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/T79i1u04VjM?si=psfJUtSFJMWeELLA"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full h-full my-[46px] flex items-center justify-center">
        <div className="border-[3px] shadow-css px-[20px] pt-[40px] pb-[40px] bg-ategbe-brown border-ategbe-green rounded-[50px] w-10/12">
          <p className="text-center text-[56px] leading-[51px] text-ategbe-font-white font-AfricaVibesRegular cursor-default">
            {currentLanguage === "portuguese"
              ? "Sobre ategbe movement"
              : "about ategbe movement"}
          </p>
          <div className="text-left w-full flex justify-center text-[16px] leading-[22.2px] text-[#D9D9D9] font-FaktumRegular cursor-default">
            {currentLanguage === "portuguese" ? (
              <div>
                <p className="mt-[34px]">
                  Fundado em 2020, Ategbe Media tem como objetivo ser um canal
                  líder na promoção, proteção e restauração da fascinante e
                  invejável cultura do povo negro. A companhia trabalha em
                  conjunto com o Projeto Olú-Aprenda Yorùbá, do Brasil, dentre
                  muitos outros.
                </p>
                <p className="mt-[14px]">
                  Nós conduzimos sessões e aulas sobre Ifá e Orixá e engajamos
                  nossa audiência e estudantes através de programas, podcasts e
                  uma série de documentários sobre o Isese. Além disso, a
                  instituição encoraja o aprendizado presencial enquanto
                  oferecemos treinamento para aqueles que pretendem trabalhar
                  com criação de vídeos (na Nigéria) e aqueles que aderem ao
                  Isese através do estudo de Ifá e dos orixás.
                </p>
                <p className="mt-[14px] mb-[54px]">
                  A instituição tem ramos em diferentes cidades pela Nigéria,
                  incluindo Lagos, Ibadan, Ife e Osogbo. A sede está localizada
                  em Lagos Ikeja e está aberta para negócios todos os dias.
                </p>

                <div className="w-full mt-[44px] flex items-center justify-center cursor-pointer">
                  <div className="flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
                    <iframe
                      className="rounded-xl"
                      width="250"
                      height="315"
                      src="https://www.youtube.com/embed/T79i1u04VjM?si=psfJUtSFJMWeELLA"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className="mt-[34px] ">
                  Established in 2020. Ategbe media sets out itself to be a
                  leading media hub for the promotion, protection and
                  restoration of the enviable and fascinating culture of the
                  black race. The company is in partnership with projeto olú
                  (learn yòrùbá) from Brazil amongst many others.
                </p>

                <p className="mt-[14px]">
                  We conduct online session and classes on IFA and Orisa worship
                  and practices engaging our audience cum students through our
                  live programmes, podcasts and series of Isese documentaries.
                  Also, the company encourages physical learning as we offer
                  training to aspiring videographers and intending adherents of
                  Isese through IFA and Orisa studies.
                </p>

                <p className="mt-[14px] mb-[24px] ">
                  The company has branches in major different town and cities of
                  Nigeria including Lagos, Ibadan, Ife and Osogbo. The Lagos
                  Ikeja branch serves as the headquarters as it is always
                  reachable and open to businesses 24 hours everyday.
                </p>

                <div className="w-full mt-[44px] flex items-center justify-center cursor-pointer">
                  <div className="flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700">
                    <iframe
                      className="rounded-xl"
                      width="250"
                      height="315"
                      src="https://www.youtube.com/embed/T79i1u04VjM?si=psfJUtSFJMWeELLA"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6" />
        </>
      )}
    </div>
  );
}

export default Section2About;
