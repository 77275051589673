import React from "react";
import { ReactComponent as CancelIcon } from "../../assets/svg/general/cancel-icon.svg";
import { ReactComponent as LanguageIcon } from "../../assets/svg/header/language-icon.svg";
import Buttons from "../buttons/buttons";

function LanguageDialog({ currentLanguage, setCurrentLanguage }) {
  /** handles selection from language dialog */
  const handleSelectLanguage = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage);
    localStorage.setItem("currentLanguage", selectedLanguage);
  };

  if (currentLanguage !== null) {
    return <span />;
  }
  return (
    <div>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="relative w-[300px] md:w-[500px] lg:w-[500px] my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="border-b border-solid rounded-t">
              <div className="flex items-center justify-between p-6">
                <div>
                  <h3 className="text-2xl font-FaktumRegular items-start flex md:flex lg:flex">
                    Select Preferred Language{" "}
                    <LanguageIcon className="w-[40px] mr-4 mt-1 md:ml-2 md:mt-2 lg:ml-2 lg:mt-1" />
                  </h3>
                </div>
                <div>
                  <button
                    type="button"
                    className="hover:bg-gray-100 -mt-[30px] md:-mt-0 lg:-mt-0  "
                    onClick={() => {
                      setCurrentLanguage(false);
                    }}
                  >
                    <CancelIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="py-8 px-10 md:px-16 lg:px-16 flex items-center justify-between md:justify-evenly lg:justify-evenly">
              <Buttons
                specificButtonTitle="English"
                specificButtonText="English"
                specificButtonTextStyling="border px-4 pt-1 pb-1 border-ategbe-green rounded-md font-FaktumRegular text-center text-base bg-green-500 text-ategbe-font-white hover:text-ategbe-font-black hover:bg-white"
                specificButtonClick={() => handleSelectLanguage("english")}
              />
              <Buttons
                specificButtonTitle="Português"
                specificButtonText="Português"
                specificButtonTextStyling="border px-4 pt-1 pb-1 border-ategbe-green rounded-md font-FaktumRegular text-center text-base bg-green-500 text-ategbe-font-white hover:text-ategbe-font-black hover:bg-white"
                specificButtonClick={() => handleSelectLanguage("portuguese")}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        onClick={() => {
          setCurrentLanguage(false);
        }}
        className="opacity-25 fixed inset-0 z-30 bg-green-400"
      />
    </div>
  );
}

export default LanguageDialog;
