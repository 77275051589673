import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import AtegbeLogo from "../../assets/img/ategbe-icon.png";

function Footer() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="mt-[50px] cursor-default">
        <div className="flex items-center justify-center">
          <div className="w-full flex justify-evenly">
            <div className="w-[326px]">
              <img
                alt="Ategbe"
                title="Ategbe"
                src={AtegbeLogo}
                className="h-[120px] mb-4"
              />
              {currentLanguage === "portuguese" ? (
                <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                  Ategbe é uma empresa de mídia que ultrapassa os limites do
                  Yorùbá Cultura através de recursos, roupas, mercadorias e
                  também filmando ativamente os eventos culturais e tradicionais
                  iorubás de classe mundial documentários..
                </p>
              ) : (
                <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                  Ategbe is a Media Company that pushes the boundaries of Yorùbá
                  Culture through resources, clothing, merchandise and also
                  actively filming world-class Yoruba cultural and traditional
                  documentaries.
                </p>
              )}
            </div>

            <div>
              <p className="mb-3 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
                {currentLanguage === "portuguese"
                  ? "Links Rápidos"
                  : "Quick links"}
              </p>
              <nav>
                <ol>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={currentLanguage === "portuguese" ? "Lar" : "Home"}
                      // onClick={() => navigate(NonAuthRoutes.landingPage)}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese" ? "Lar" : "Home"}
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={
                        currentLanguage === "portuguese"
                          ? "Sobre nós"
                          : "About Us"
                      }
                      // onClick={() => navigate(NonAuthRoutes.landingPage)}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese"
                          ? "Sobre nós"
                          : "About Us"}
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={
                        currentLanguage === "portuguese"
                          ? "Recursos"
                          : "Resources"
                      }
                      // onClick={() => navigate(NonAuthRoutes.landingPage)}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese"
                          ? "Recursos"
                          : "Resources"}
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title={
                        currentLanguage === "portuguese"
                          ? "Contacto"
                          : "Contact Us"
                      }
                      // onClick={() => navigate(NonAuthRoutes.landingPage)}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        {currentLanguage === "portuguese"
                          ? "Contacto"
                          : "Contact Us"}
                      </p>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <p className="mb-3 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
                {currentLanguage === "portuguese" ? "Apoiar" : "Support"}
              </p>
              <nav>
                <ol>
                  <li className="mb-4">
                    <button
                      type="button"
                      title="WhatsApp"
                      onClick={() => navigate(NonAuthRoutes.contact)}
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        WhatsApp
                      </p>
                    </button>
                  </li>
                  <li className="mb-4">
                    <button
                      type="button"
                      title="Telegram"
                      // onClick={() =>
                      //   navigate(NonAuthRoutes.play, {
                      //     state: { sectionName: "Telegram" },
                      //   })
                      // }
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        Telegram
                      </p>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      title="Youtube"
                      // onClick={() =>
                      //   navigate(NonAuthRoutes.ourStory, {
                      //     state: { sectionName: "Youtube" },
                      //   })
                      // }
                    >
                      <p className=" font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                        Youtube
                      </p>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <p className="mb-3 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
                {currentLanguage === "portuguese"
                  ? "Horário de funcionamento"
                  : "Opening Hours"}
              </p>
              <nav>
                <ol>
                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Segunda-feira - Quinta-feira"
                        : "Monday - Thursday"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      9AM - 8PM
                    </li>
                  </div>

                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Sexta-feira - Sábado"
                        : "Friday - Saturday"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      9AM - 9PM
                    </li>
                  </div>

                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese" ? "Domingo" : "Sunday"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      12PM - 8PM
                    </li>
                  </div>

                  <div className="flex items-center justify-between w-[352px]">
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Feriados públicos"
                        : "Public Holidays"}
                    </li>
                    <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese" ? "Abrir" : "Open"}
                    </li>
                  </div>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="h-[1px] mt-[50px] mx-[110px] bg-[#3C6791] opacity-50" />
        <div className="h-[73px] flex items-center justify-center">
          <div>
            {currentLanguage === "portuguese" ? (
              <p className="mb-4 mt-6 font-FaktumBold text-[16px] leading-[24px] text-ategbe-green text-center ">
                © Direitos autorais 2023. Marcas registradas e marcas são
                propriedade da Ategbe Media LTD.
              </p>
            ) : (
              <p className="mb-4 mt-6 font-FaktumBold text-[16px] leading-[24px] text-ategbe-green text-center ">
                © Copyright 2023. Trademarks and Brands are the Property of
                Ategbe Media LTD.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="mt-[50px] cursor-default">
        <div className="w-full px-[50px]">
          <div className="">
            <img
              alt="Ategbe"
              title="Ategbe"
              src={AtegbeLogo}
              className="h-[120px] mb-4"
            />
            {currentLanguage === "portuguese" ? (
              <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                Ategbe é uma empresa de mídia que ultrapassa os limites do
                Yorùbá Cultura através de recursos, roupas, mercadorias e também
                filmando ativamente os eventos culturais e tradicionais iorubás
                de classe mundial documentários..
              </p>
            ) : (
              <p className="font-Popp-insRegular text-ategbe-font-grey text-[16px] leading-[24px]">
                Ategbe is a Media Company that pushes the boundaries of Yorùbá
                Culture through resources, clothing, merchandise and also
                actively filming world-class Yoruba cultural and traditional
                documentaries.
              </p>
            )}
          </div>

          <div>
            <p className="mb-3 mt-10 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
              {currentLanguage === "portuguese"
                ? "Links Rápidos"
                : "Quick links"}
            </p>
            <nav>
              <ol>
                <li className="mb-4">
                  <button
                    type="button"
                    title={currentLanguage === "portuguese" ? "Lar" : "Home"}
                    // onClick={() => navigate(NonAuthRoutes.landingPage)}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese" ? "Lar" : "Home"}
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title={
                      currentLanguage === "portuguese"
                        ? "Sobre nós"
                        : "About Us"
                    }
                    // onClick={() => navigate(NonAuthRoutes.landingPage)}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Sobre nós"
                        : "About Us"}
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title={
                      currentLanguage === "portuguese"
                        ? "Recursos"
                        : "Resources"
                    }
                    // onClick={() => navigate(NonAuthRoutes.landingPage)}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Recursos"
                        : "Resources"}
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title={
                      currentLanguage === "portuguese"
                        ? "Contacto"
                        : "Contact Us"
                    }
                    // onClick={() => navigate(NonAuthRoutes.landingPage)}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      {currentLanguage === "portuguese"
                        ? "Contacto"
                        : "Contact Us"}
                    </p>
                  </button>
                </li>
              </ol>
            </nav>
          </div>

          <div>
            <p className="mb-3 mt-5 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
              {currentLanguage === "portuguese" ? "Apoiar" : "Support"}
            </p>
            <nav>
              <ol>
                <li className="mb-4">
                  <button
                    type="button"
                    title="WhatsApp"
                    onClick={() => navigate(NonAuthRoutes.contact)}
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      WhatsApp
                    </p>
                  </button>
                </li>
                <li className="mb-4">
                  <button
                    type="button"
                    title="Telegram"
                    // onClick={() =>
                    //   navigate(NonAuthRoutes.play, {
                    //     state: { sectionName: "Telegram" },
                    //   })
                    // }
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      Telegram
                    </p>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    title="Youtube"
                    // onClick={() =>
                    //   navigate(NonAuthRoutes.ourStory, {
                    //     state: { sectionName: "Youtube" },
                    //   })
                    // }
                  >
                    <p className="font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                      Youtube
                    </p>
                  </button>
                </li>
              </ol>
            </nav>
          </div>

          <div>
            <p className="mb-3 mt-5 font-AfricaVibesRegular text-[50px] leading-[37px] text-ategbe-green">
              {currentLanguage === "portuguese"
                ? "Horário de funcionamento"
                : "Opening Hours"}
            </p>
            <nav>
              <ol>
                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese"
                      ? "Segunda-feira - Quinta-feira"
                      : "Monday - Thursday"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    9AM - 8PM
                  </li>
                </div>

                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese"
                      ? "Sexta-feira - Sábado"
                      : "Friday - Saturday"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    9AM - 9PM
                  </li>
                </div>

                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese" ? "Domingo" : "Sunday"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    12PM - 8PM
                  </li>
                </div>

                <div className="flex items-center justify-between">
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese"
                      ? "Feriados públicos"
                      : "Public Holidays"}
                  </li>
                  <li className="mb-4 font-FaktumRegular text-[16px] leading-[24px] text-[#d9d9d9]">
                    {currentLanguage === "portuguese" ? "Abrir" : "Open"}
                  </li>
                </div>
              </ol>
            </nav>
          </div>
        </div>
        <div className="h-[1px] mt-[50px] mx-[110px] bg-[#3C6791] opacity-50" />
        <div className="h-[73px] flex items-center justify-center px-7">
          <div>
            {currentLanguage === "portuguese" ? (
              <p className="mb-4 mt-6 font-FaktumBold text-[12px] leading-[14px] text-ategbe-green text-center ">
                © Direitos autorais 2023. Marcas registradas e marcas são
                propriedade da Ategbe Media LTD.
              </p>
            ) : (
              <p className="mb-4 mt-6 font-FaktumBold text-[12px] leading-[14px text-ategbe-green text-center ">
                © Copyright 2023. Trademarks and Brands are the Property of
                Ategbe Media LTD.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <div>{width > 1188 ? <>{desktop()}</> : <>{mobile()}</>}</div>;
}

export default Footer;
